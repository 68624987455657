//Define the fallback environment
export const defaultEnvironment = 'local'

//List of all environments including there variables
//  - you can choose the environment by the host variable `EF_ENVIRONMENT`
//  - the names have to be uppercase and underline separated
//  - use only a flat structure
//  - to reference a variable use this pattern `env => env.ENV_VAR_NAME`
//      `env` includes only the selected environment object
const HEALTH_PATH = 'actuator/health'

const API_PRODUKTE_BASE_PATH = '/api/produkte/'
const API_PRODUKTE_ZWM_BASE_PATH = '/api/produkte-zwm/'
const API_AUFTRAEGE_BASE_PATH = '/api/auftraege/'
const API_VERMITTLUNGEN_BASE_PATH = '/api/vermittlungen/'
const API_AUTH_BASE_PATH = '/api/auth/'
const API_LOGINS_BASE_PATH = '/api/logins/'
const API_FDB_STAMMDATEN_BASE_PATH = '/api/fdb-stammdaten/'
const API_REGISTRATION_BASE_PATH = '/api/registration/'
const API_SUBSITE_CONTENT_BASE_PATH = '/api/subsite-content/'


const API_PRODUKTE_PATH =  API_PRODUKTE_BASE_PATH + 'v2/'
const API_PRODUKTE_PATH_BEOBACHTUNGEN = API_PRODUKTE_PATH + 'beobachtungen'
const API_PRODUKTE_PATH_BEOBACHTUNGEN_ADD = API_PRODUKTE_PATH_BEOBACHTUNGEN + '/{id}'
const API_PRODUKTE_PATH_BEOBACHTUNGEN_DELETE = API_PRODUKTE_PATH_BEOBACHTUNGEN + '/{id}'

const API_PRODUKTE_ZWM_PATH = API_PRODUKTE_ZWM_BASE_PATH + 'v1/zweitmarkt'
const API_PRODUKTE_ZWM_ANBIETER_PATH = API_PRODUKTE_ZWM_BASE_PATH + 'v1/anbieter'
const API_PRODUKTE_ZWM_CURRENCIES_PATH = API_PRODUKTE_ZWM_BASE_PATH + 'v1/currencies'
const API_PRODUKTE_ZWM_ASSETKLASSEN_PATH = API_PRODUKTE_ZWM_BASE_PATH + 'v1/assetklassen'
const API_PRODUKTE_ZWM_DECODE_TRANCHE_ID_PATH = API_PRODUKTE_ZWM_BASE_PATH + 'v1/decodetrancheid'
const API_PRODUKTE_ZWM_TRANCHE_ID_PATH = API_PRODUKTE_ZWM_BASE_PATH + 'v1/tranche/{id}'

const API_TRANCHE_PATH =  API_PRODUKTE_ZWM_BASE_PATH + 'v1/tranche/'
const API_TRANCHE_NEWS_PATH =  API_PRODUKTE_ZWM_BASE_PATH + 'v1/tranchenews/'
const API_TRANCHE_VERMITTLUNGEN_PATH =  API_PRODUKTE_ZWM_BASE_PATH + 'v1/tranchevermittlungen/'
const API_TRANCHE_VERMITTLUNGEN_HISTORIC_PATH =  API_PRODUKTE_ZWM_BASE_PATH + 'v1/tranchevermittlungenhistory/'
const API_TRANCHE_HANDELSINFORMATIONEN_PATH =  API_PRODUKTE_ZWM_BASE_PATH + 'v1/tranchehandelsinformationen/{id}'
const API_TRANCHE_ORDERBUCH_PATH =  API_AUFTRAEGE_BASE_PATH + 'v1/tranche/{id}'
const API_KAEUFERMAIL_PATH =  API_AUFTRAEGE_BASE_PATH + 'v1/kaeufermail/{mandant}'
const API_KAEUFERMAILDATE_PATH =  API_AUFTRAEGE_BASE_PATH + 'v1/kaeufermaildate/{mandant}'
const API_LOGIN_PATH = API_AUTH_BASE_PATH + 'v3/login/'
// Auftraege
const API_USER_AUFTRAEGE_PATH =  API_AUFTRAEGE_BASE_PATH + 'v1/findforuser'
const API_USER_AUFTRAGNOTE_PATH =  API_AUFTRAEGE_BASE_PATH + 'v1/note/{auftragId}'
const API_AUFTRAGID_PATH = API_AUFTRAEGE_BASE_PATH + 'v1/id/{auftragNr}'
const API_AUFTRAGDETAILS_HEADER = API_AUFTRAEGE_BASE_PATH + 'v1/header/{auftragId}'
const API_AUFTRAGDETAILS_HISTORIE = API_AUFTRAEGE_BASE_PATH + 'v1/historie/{auftragId}'
const API_AUFTRAGDOKUMENTE_PATH = API_AUFTRAEGE_BASE_PATH + 'v1/dokumente/{auftragId}'
const API_AUFTRAEGE_BIETVERFAHREN_RUNNING_PATH = API_AUFTRAEGE_BASE_PATH + 'v1/bietverfahren-in-progress/{auftragId}'
const API_AUFTRAG_ANLAGE_PATH = API_AUFTRAEGE_BASE_PATH + 'v1'
const API_AUFTRAG_AENDERUNG_PATH = API_AUFTRAEGE_BASE_PATH + 'v1/modify'
const API_AUFTRAG_LOESCHUNG_PATH = API_AUFTRAEGE_BASE_PATH + 'v1/delete/{auftragId}'
// Password handling
const API_PASSWORD_SERVICE = API_LOGINS_BASE_PATH + 'v1/{subsite}/password/'
const API_PASSWORD_GUIDELINES_PATH = API_PASSWORD_SERVICE + 'guidelines' // HTTP GET
const API_PASSWORD_GUIDELINE_CHECK_PATH = API_PASSWORD_SERVICE + 'guidelines' // HTTP POST
const API_PASSWORD_HINT_PATH = API_PASSWORD_SERVICE + 'hint' // HTTP GET
const API_PASSWORD_CHANGE_PATH = API_PASSWORD_SERVICE + 'change' // HTTP POST
const API_PASSWORD_RESETREQUEST_PATH = API_PASSWORD_SERVICE + 'reset' // HTTP POST
// User profile
const API_USER_PROFILE_SERVICE = API_FDB_STAMMDATEN_BASE_PATH + 'v2/{guid}/profilminimal'
const API_KUNDENDATEN_SERVICE = API_FDB_STAMMDATEN_BASE_PATH + 'v2/{guid}/kundendaten'
const API_ADRESSE_SERVICE = API_FDB_STAMMDATEN_BASE_PATH + 'v2/{guid}/adresse'
const API_TELEFON_SERVICE = API_FDB_STAMMDATEN_BASE_PATH + 'v2/{guid}/telefon'
const API_FAX_SERVICE = API_FDB_STAMMDATEN_BASE_PATH + 'v2/{guid}/fax'
const API_LAENDER_SERVICE = API_FDB_STAMMDATEN_BASE_PATH + 'v1/wertelisten/laender'
const API_INFOSERVICES_SERVICE = API_LOGINS_BASE_PATH + 'v1/{loginId}/infoservices'
// Vermittlungen
const API_USER_VERMITTLUNGEN_PATH = API_VERMITTLUNGEN_BASE_PATH + 'v1/findforuser'
const API_USER_VERMITTLUNGNOTE_PATH =  API_VERMITTLUNGEN_BASE_PATH + 'v1/note/{vermittlungId}'
const API_VERMITTLUNGID_PATH = API_VERMITTLUNGEN_BASE_PATH + 'v1/id/{vermittlungNr}'
const API_VERMITTLUNGDETAILS_HEADER = API_VERMITTLUNGEN_BASE_PATH + 'v1/header/{vermittlungId}'
const API_VERMITTLUNGDETAILS_HISTORIE = API_VERMITTLUNGEN_BASE_PATH + 'v1/datumsliste/{vermittlungId}'
// Registration
const API_REGISTRATION_START_PATH = API_REGISTRATION_BASE_PATH + 'v1/start/{subsite}'
const API_REGISTRATION_CONFIRM_PATH = API_REGISTRATION_BASE_PATH + 'v1/confirm/{subsite}'
const API_REGISTRATION_NEWSLETTERCONFIRM_PATH = API_REGISTRATION_BASE_PATH + 'v1/confirmnewsletter/{subsite}'
const API_REGISTRATION_LOGINCONFIRM_PATH = API_REGISTRATION_BASE_PATH + 'v1/confirmlogin/{subsite}'
// View-Begrenzung
const API_VISIBILITY_CHECK_PATH = API_PRODUKTE_ZWM_BASE_PATH + 'v1/tranche/hasviewsleft'
// Downloads-Begrenzung
const API_DOWNLOADS_CHECK_PATH = API_PRODUKTE_ZWM_BASE_PATH + 'v1/tranche/hasdownloadsleft'
// Vertraulichkeitszustimmung
const API_VERTRAULICHKEITSZUSTIMMUNG_PATH = API_FDB_STAMMDATEN_BASE_PATH + 'v2/{guid}/kundendaten/vertraulichkeitszustimmung_gueltig'
const API_VERTRAULICHKEITSZUSTIMMUNG_DOWNLOADS_PATH = API_PRODUKTE_ZWM_BASE_PATH + 'v1/tranche/{id}/vertraulichkeitszustimmung'
// Subsite content
const API_SUBSITE_CONTENT_PATH = API_SUBSITE_CONTENT_BASE_PATH + 'v1'
// Unsubscribe
const API_LOGINS_UNSUBSCRIBE_PATH = API_LOGINS_BASE_PATH + 'v1/{subsite}/unsubscribe/{token}'
const API_STAMMDATEN_UNSUBSCRIBE_PATH = API_FDB_STAMMDATEN_BASE_PATH + 'v2/{subsite}/unsubscribe/{token}'

export const environments = {
  local: {
    API_STAGE: 'local',
    API_EFONDS: 'https://testgateway.efonds.com',
    URL_EXT_SYSTEM: 'http://localhost:11080',
    API_KEY: 'bcf5ee00-c72a-433d-abd5-edd734f4928a',

    PRODUKTE_ZWM_SERVER: 'http://localhost:8086', 
    PRODUKTE_SERVER: 'http://localhost:8085',
    AUFTRAEGE_SERVER: 'http://localhost:8087',  
    AUTH_SERVER: 'http://localhost:8082',
    VERMITTLUNGEN_SERVER: 'http://localhost:8091', 
    REGISTRATION_SERVER: 'http://localhost:8094',
    FDB_STAMMDATEN_SERVER: 'http://localhost:8095',
    LOGINS_SERVER: 'http://localhost:8089',
    SUBSITE_CONTENT_SERVER: 'http://localhost:9015',  
      
    API_PRODUKTE_ZWM_HEALTH: env => env.PRODUKTE_ZWM_SERVER + API_PRODUKTE_ZWM_BASE_PATH + HEALTH_PATH,
    API_PRODUKTE_HEALTH: env => env.PRODUKTE_SERVER + API_PRODUKTE_BASE_PATH + HEALTH_PATH, 
    API_AUTH_HEALTH: env => env.AUTH_SERVER + API_AUTH_BASE_PATH + HEALTH_PATH,
    API_AUFTRAEGE_HEALTH: env => env.AUFTRAEGE_SERVER + API_AUFTRAEGE_BASE_PATH + HEALTH_PATH,
    API_VERMITTLUNGEN_HEALTH: env => env.VERMITTLUNGEN_SERVER + API_VERMITTLUNGEN_BASE_PATH + HEALTH_PATH,
    API_REGISTRATION_HEALTH: env => env.REGISTRATION_SERVER + API_REGISTRATION_BASE_PATH + HEALTH_PATH,
    API_STAMMDATEN_HEALTH: env => env.FDB_STAMMDATEN_SERVER + API_FDB_STAMMDATEN_BASE_PATH + HEALTH_PATH,
    API_LOGINS_HEALTH: env => env.LOGINS_SERVER + API_LOGINS_BASE_PATH + HEALTH_PATH, 
    API_SUBSITE_CONTENT_HEALTH: env => env.SUBSITE_CONTENT_SERVER + API_SUBSITE_CONTENT_BASE_PATH + HEALTH_PATH,
      
    API_PRODUKTE_ZWM: env => env.PRODUKTE_ZWM_SERVER + API_PRODUKTE_ZWM_PATH,
    API_PRODUKTE_ZWM_TRANCHE: env => env.PRODUKTE_ZWM_SERVER + API_TRANCHE_PATH,
    API_PRODUKTE_ZWM_TRANCHE_NEWS: env => env.PRODUKTE_ZWM_SERVER + API_TRANCHE_NEWS_PATH,
    API_PRODUKTE_ZWM_TRANCHE_VERMITTLUNGEN: env => env.PRODUKTE_ZWM_SERVER + API_TRANCHE_VERMITTLUNGEN_PATH,
    API_PRODUKTE_ZWM_TRANCHE_VERMITTLUNGEN_HISTORIC: env => env.PRODUKTE_ZWM_SERVER + API_TRANCHE_VERMITTLUNGEN_HISTORIC_PATH,
    API_PRODUKTE_ZWM_ANBIETER: env => env.PRODUKTE_ZWM_SERVER + API_PRODUKTE_ZWM_ANBIETER_PATH,
    API_PRODUKTE_ZWM_CURRENCIES: env => env.PRODUKTE_ZWM_SERVER + API_PRODUKTE_ZWM_CURRENCIES_PATH,
    API_PRODUKTE_ZWM_ASSETKLASSEN: env => env.PRODUKTE_ZWM_SERVER + API_PRODUKTE_ZWM_ASSETKLASSEN_PATH,
    API_PRODUKTE_ZWM_DECODE_TRANCHE_ID: env => env.PRODUKTE_ZWM_SERVER + API_PRODUKTE_ZWM_DECODE_TRANCHE_ID_PATH,
    API_PRODUKTE_ZWM_TRANCHE_ID: env => env.PRODUKTE_ZWM_SERVER + API_PRODUKTE_ZWM_TRANCHE_ID_PATH,
    API_PRODUKTE_BEOBACHTUNGEN: env => env.PRODUKTE_SERVER + API_PRODUKTE_PATH_BEOBACHTUNGEN,
    API_PRODUKTE_BEOBACHTUNGEN_ADD: env => env.PRODUKTE_SERVER + API_PRODUKTE_PATH_BEOBACHTUNGEN_ADD,
    API_PRODUKTE_BEOBACHTUNGEN_DELETE: env => env.PRODUKTE_SERVER + API_PRODUKTE_PATH_BEOBACHTUNGEN_DELETE,
    API_TRANCHE_ORDERBUCH: env => env.AUFTRAEGE_SERVER + API_TRANCHE_ORDERBUCH_PATH,
    API_TRANCHE_HANDELSINFORMATIONEN: env => env.PRODUKTE_ZWM_SERVER + API_TRANCHE_HANDELSINFORMATIONEN_PATH,
    API_KAEUFERMAIL: env => env.AUFTRAEGE_SERVER + API_KAEUFERMAIL_PATH,
    API_KAEUFERMAILDATE: env => env.AUFTRAEGE_SERVER + API_KAEUFERMAILDATE_PATH, 
    API_USER_AUFTRAEGE: env => env.AUFTRAEGE_SERVER + API_USER_AUFTRAEGE_PATH,
    API_USER_AUFTRAGNOTE: env => env.AUFTRAEGE_SERVER + API_USER_AUFTRAGNOTE_PATH,
    API_AUFTRAGID: env => env.AUFTRAEGE_SERVER + API_AUFTRAGID_PATH,
    API_AUFTRAGHEADER: env => env.AUFTRAEGE_SERVER + API_AUFTRAGDETAILS_HEADER,
    API_AUFTRAGHISTORIE: env => env.AUFTRAEGE_SERVER + API_AUFTRAGDETAILS_HISTORIE,
    API_AUFTRAGDOKUMENTE: env => env.AUFTRAEGE_SERVER + API_AUFTRAGDOKUMENTE_PATH,
    API_BIETVERFAHREN_IN_PROGRESS: env => env.AUFTRAEGE_SERVER + API_AUFTRAEGE_BIETVERFAHREN_RUNNING_PATH,
    API_AUFTRAG_ANLAGE: env => env.AUFTRAEGE_SERVER + API_AUFTRAG_ANLAGE_PATH,
    API_AUFTRAG_AENDERUNG: env => env.AUFTRAEGE_SERVER + API_AUFTRAG_AENDERUNG_PATH,
    API_AUFTRAG_LOESCHUNG: env => env.AUFTRAEGE_SERVER + API_AUFTRAG_LOESCHUNG_PATH,
    API_USER_VERMITTLUNGEN: env => env.VERMITTLUNGEN_SERVER + API_USER_VERMITTLUNGEN_PATH,
    API_USER_VERMITTLUNGNOTE: env => env.VERMITTLUNGEN_SERVER + API_USER_VERMITTLUNGNOTE_PATH,
    API_VERMITTLUNGID: env => env.VERMITTLUNGEN_SERVER + API_VERMITTLUNGID_PATH,
    API_VERMITTLUNGHEADER: env => env.VERMITTLUNGEN_SERVER + API_VERMITTLUNGDETAILS_HEADER,
    API_VERMITTLUNGHISTORIE: env => env.VERMITTLUNGEN_SERVER + API_VERMITTLUNGDETAILS_HISTORIE,
    API_LOGIN: env => env.AUTH_SERVER + API_LOGIN_PATH,
    API_PASSWORD_GUIDLINES: env => env.LOGINS_SERVER + API_PASSWORD_GUIDELINES_PATH,
    API_PASSWORD_HINT: env => env.LOGINS_SERVER + API_PASSWORD_HINT_PATH,
    API_PASSWORD_CHECK: env => env.LOGINS_SERVER + API_PASSWORD_GUIDELINE_CHECK_PATH,
    API_PASSWORD_CHANGE: env => env.LOGINS_SERVER + API_PASSWORD_CHANGE_PATH,
    API_PASSWORD_RESETREQUEST: env => env.LOGINS_SERVER + API_PASSWORD_RESETREQUEST_PATH,
    API_REGISTRATION_START: env => env.REGISTRATION_SERVER + API_REGISTRATION_START_PATH,
    API_REGISTRATION_CONFIRM: env => env.REGISTRATION_SERVER + API_REGISTRATION_CONFIRM_PATH,
    API_REGISTRATION_NEWSLETTERCONFIRM: env => env.REGISTRATION_SERVER + API_REGISTRATION_NEWSLETTERCONFIRM_PATH,
    API_REGISTRATION_LOGINCONFIRM: env => env.REGISTRATION_SERVER + API_REGISTRATION_LOGINCONFIRM_PATH,
    API_VISIBILITY_CHECK: env => env.PRODUKTE_ZWM_SERVER + API_VISIBILITY_CHECK_PATH,
    API_DOWNLOADS_CHECK: env => env.PRODUKTE_ZWM_SERVER + API_DOWNLOADS_CHECK_PATH,
    API_VERTRAULICHKEITSZUSTIMMUNG: env => env.FDB_STAMMDATEN_SERVER + API_VERTRAULICHKEITSZUSTIMMUNG_PATH,
    API_VERTRAULICHKEITSZUSTIMMUNG_DOWNLOADS: env => env.PRODUKTE_ZWM_SERVER + API_VERTRAULICHKEITSZUSTIMMUNG_DOWNLOADS_PATH,
    API_USER_PROFILE: env => env.FDB_STAMMDATEN_SERVER + API_USER_PROFILE_SERVICE,
    API_KUNDENDATEN: env => env.FDB_STAMMDATEN_SERVER + API_KUNDENDATEN_SERVICE,
    API_ADRESSE: env => env.FDB_STAMMDATEN_SERVER + API_ADRESSE_SERVICE,
    API_TELEFON: env => env.FDB_STAMMDATEN_SERVER + API_TELEFON_SERVICE,
    API_FAX: env => env.FDB_STAMMDATEN_SERVER + API_FAX_SERVICE,
    API_LAENDER: env => env.FDB_STAMMDATEN_SERVER + API_LAENDER_SERVICE,
    API_INFOSERVICES: env => env.LOGINS_SERVER + API_INFOSERVICES_SERVICE,
    API_SUBSITE_CONTENT: env => env.SUBSITE_CONTENT_SERVER + API_SUBSITE_CONTENT_PATH,
      
    API_LOGINS_UNSUBSCRIBE: env => env.LOGINS_SERVER + API_LOGINS_UNSUBSCRIBE_PATH,
    API_STAMMDATEN_UNSUBSCRIBE: env => env.FDB_STAMMDATEN_SERVER + API_STAMMDATEN_UNSUBSCRIBE_PATH,
    MATOMO_SITEID: 4711,
  },
  dev: {
    API_STAGE: 'dev',
    API_EFONDS: 'https://efapi.dev.efonds.ag',
    URL_EXT_SYSTEM: 'https://wicket9test.dev.efonds.ag',
    API_KEY: 'bcf5ee00-c72a-433d-abd5-edd734f4928a',

    API_PRODUKTE_ZWM_HEALTH: env => env.API_EFONDS + API_PRODUKTE_ZWM_BASE_PATH + HEALTH_PATH,
    API_PRODUKTE_HEALTH: env => env.API_EFONDS + API_PRODUKTE_BASE_PATH + HEALTH_PATH,
    API_AUTH_HEALTH: env => env.API_EFONDS + API_AUTH_BASE_PATH + HEALTH_PATH,
    API_AUFTRAEGE_HEALTH: env => env.API_EFONDS + API_AUFTRAEGE_BASE_PATH + HEALTH_PATH,
    API_VERMITTLUNGEN_HEALTH: env => env.API_EFONDS + API_VERMITTLUNGEN_BASE_PATH + HEALTH_PATH,
    API_REGISTRATION_HEALTH: env => env.API_EFONDS + API_REGISTRATION_BASE_PATH + HEALTH_PATH,
    API_STAMMDATEN_HEALTH: env => env.API_EFONDS + API_FDB_STAMMDATEN_BASE_PATH + HEALTH_PATH,
    API_LOGINS_HEALTH: env => env.API_EFONDS + API_LOGINS_BASE_PATH + HEALTH_PATH,
    API_SUBSITE_CONTENT_HEALTH: env => env.API_EFONDS + API_SUBSITE_CONTENT_BASE_PATH + HEALTH_PATH,  
      
    API_PRODUKTE_ZWM: env => env.API_EFONDS + API_PRODUKTE_ZWM_PATH,
    API_PRODUKTE_ZWM_TRANCHE: env => env.API_EFONDS + API_TRANCHE_PATH,
    API_PRODUKTE_ZWM_TRANCHE_NEWS: env => env.API_EFONDS + API_TRANCHE_NEWS_PATH,
    API_PRODUKTE_ZWM_TRANCHE_VERMITTLUNGEN: env => env.API_EFONDS + API_TRANCHE_VERMITTLUNGEN_PATH,
    API_PRODUKTE_ZWM_TRANCHE_VERMITTLUNGEN_HISTORIC: env => env.API_EFONDS + API_TRANCHE_VERMITTLUNGEN_HISTORIC_PATH,
    API_PRODUKTE_ZWM_ANBIETER: env => env.API_EFONDS + API_PRODUKTE_ZWM_ANBIETER_PATH,
    API_PRODUKTE_ZWM_CURRENCIES: env => env.API_EFONDS + API_PRODUKTE_ZWM_CURRENCIES_PATH,
    API_PRODUKTE_ZWM_ASSETKLASSEN: env => env.API_EFONDS + API_PRODUKTE_ZWM_ASSETKLASSEN_PATH,
    API_PRODUKTE_ZWM_DECODE_TRANCHE_ID: env => env.API_EFONDS + API_PRODUKTE_ZWM_DECODE_TRANCHE_ID_PATH,
    API_PRODUKTE_ZWM_TRANCHE_ID: env => env.API_EFONDS + API_PRODUKTE_ZWM_TRANCHE_ID_PATH,
    API_PRODUKTE_BEOBACHTUNGEN: env => env.API_EFONDS + API_PRODUKTE_PATH_BEOBACHTUNGEN,
    API_PRODUKTE_BEOBACHTUNGEN_ADD: env => env.API_EFONDS + API_PRODUKTE_PATH_BEOBACHTUNGEN_ADD,
    API_PRODUKTE_BEOBACHTUNGEN_DELETE: env => env.API_EFONDS + API_PRODUKTE_PATH_BEOBACHTUNGEN_DELETE,
    API_TRANCHE_ORDERBUCH: env => env.API_EFONDS + API_TRANCHE_ORDERBUCH_PATH,
    API_TRANCHE_HANDELSINFORMATIONEN: env => env.API_EFONDS + API_TRANCHE_HANDELSINFORMATIONEN_PATH,
    API_KAEUFERMAIL: env => env.API_EFONDS + API_KAEUFERMAIL_PATH,
    API_KAEUFERMAILDATE: env => env.API_EFONDS + API_KAEUFERMAILDATE_PATH,
    API_USER_AUFTRAEGE: env => env.API_EFONDS + API_USER_AUFTRAEGE_PATH,
    API_USER_AUFTRAGNOTE: env => env.API_EFONDS + API_USER_AUFTRAGNOTE_PATH,
    API_AUFTRAGID: env => env.API_EFONDS + API_AUFTRAGID_PATH,
    API_AUFTRAGHEADER: env => env.API_EFONDS + API_AUFTRAGDETAILS_HEADER,
    API_AUFTRAGHISTORIE: env => env.API_EFONDS + API_AUFTRAGDETAILS_HISTORIE,
    API_AUFTRAGDOKUMENTE: env => env.API_EFONDS + API_AUFTRAGDOKUMENTE_PATH,
    API_BIETVERFAHREN_IN_PROGRESS: env => env.API_EFONDS + API_AUFTRAEGE_BIETVERFAHREN_RUNNING_PATH,
    API_AUFTRAG_ANLAGE: env => env.API_EFONDS + API_AUFTRAG_ANLAGE_PATH,
    API_AUFTRAG_AENDERUNG: env => env.API_EFONDS + API_AUFTRAG_AENDERUNG_PATH,
    API_AUFTRAG_LOESCHUNG: env => env.API_EFONDS + API_AUFTRAG_LOESCHUNG_PATH,
    API_USER_VERMITTLUNGEN: env => env.API_EFONDS + API_USER_VERMITTLUNGEN_PATH,
    API_USER_VERMITTLUNGNOTE: env => env.API_EFONDS + API_USER_VERMITTLUNGNOTE_PATH,
    API_VERMITTLUNGID: env => env.API_EFONDS + API_VERMITTLUNGID_PATH,
    API_VERMITTLUNGHEADER: env => env.API_EFONDS + API_VERMITTLUNGDETAILS_HEADER,
    API_VERMITTLUNGHISTORIE: env => env.API_EFONDS + API_VERMITTLUNGDETAILS_HISTORIE,
    API_LOGIN: env => env.API_EFONDS + API_LOGIN_PATH,
    API_PASSWORD_GUIDLINES: env => env.API_EFONDS + API_PASSWORD_GUIDELINES_PATH,
    API_PASSWORD_HINT: env => env.API_EFONDS + API_PASSWORD_HINT_PATH,  
    API_PASSWORD_CHECK: env => env.API_EFONDS + API_PASSWORD_GUIDELINE_CHECK_PATH,
    API_PASSWORD_CHANGE: env => env.API_EFONDS + API_PASSWORD_CHANGE_PATH,
    API_PASSWORD_RESETREQUEST: env => env.API_EFONDS + API_PASSWORD_RESETREQUEST_PATH,
    API_REGISTRATION_START: env => env.API_EFONDS + API_REGISTRATION_START_PATH,
    API_REGISTRATION_CONFIRM: env => env.API_EFONDS + API_REGISTRATION_CONFIRM_PATH,
    API_REGISTRATION_NEWSLETTERCONFIRM: env => env.API_EFONDS + API_REGISTRATION_NEWSLETTERCONFIRM_PATH,
    API_REGISTRATION_LOGINCONFIRM: env => env.API_EFONDS + API_REGISTRATION_LOGINCONFIRM_PATH,
    API_VISIBILITY_CHECK: env => env.API_EFONDS + API_VISIBILITY_CHECK_PATH,
    API_DOWNLOADS_CHECK: env => env.API_EFONDS + API_DOWNLOADS_CHECK_PATH,
    API_VERTRAULICHKEITSZUSTIMMUNG: env => env.API_EFONDS + API_VERTRAULICHKEITSZUSTIMMUNG_PATH,
    API_VERTRAULICHKEITSZUSTIMMUNG_DOWNLOADS: env => env.API_EFONDS + API_VERTRAULICHKEITSZUSTIMMUNG_DOWNLOADS_PATH,
    API_USER_PROFILE: env => env.API_EFONDS + API_USER_PROFILE_SERVICE,
    API_KUNDENDATEN: env => env.API_EFONDS + API_KUNDENDATEN_SERVICE,
    API_ADRESSE: env => env.API_EFONDS + API_ADRESSE_SERVICE,
    API_TELEFON: env => env.API_EFONDS + API_TELEFON_SERVICE,
    API_FAX: env => env.API_EFONDS + API_FAX_SERVICE,
    API_LAENDER: env => env.API_EFONDS + API_LAENDER_SERVICE,
    API_INFOSERVICES: env => env.API_EFONDS + API_INFOSERVICES_SERVICE,
    API_SUBSITE_CONTENT: env => env.API_EFONDS + API_SUBSITE_CONTENT_PATH,

    API_LOGINS_UNSUBSCRIBE: env => env.API_EFONDS + API_LOGINS_UNSUBSCRIBE_PATH,
    API_STAMMDATEN_UNSUBSCRIBE: env => env.API_EFONDS + API_STAMMDATEN_UNSUBSCRIBE_PATH,
    MATOMO_SITEID: 4711,
  },
  preprod: {
    API_STAGE: 'preprod',
    API_EFONDS: 'https://testgateway.efonds.com',
    URL_EXT_SYSTEM: 'https://mzm2.efonds.com',
    API_KEY: 'bcf5ee00-c72a-433d-abd5-edd734f4928a',

    API_PRODUKTE_ZWM_HEALTH: env => env.API_EFONDS + API_PRODUKTE_ZWM_BASE_PATH + HEALTH_PATH,
    API_PRODUKTE_HEALTH: env => env.API_EFONDS + API_PRODUKTE_BASE_PATH + HEALTH_PATH,
    API_AUTH_HEALTH: env => env.API_EFONDS + API_AUTH_BASE_PATH + HEALTH_PATH,
    API_AUFTRAEGE_HEALTH: env => env.API_EFONDS + API_AUFTRAEGE_BASE_PATH + HEALTH_PATH,
    API_VERMITTLUNGEN_HEALTH: env => env.API_EFONDS + API_VERMITTLUNGEN_BASE_PATH + HEALTH_PATH,
    API_REGISTRATION_HEALTH: env => env.API_EFONDS + API_REGISTRATION_BASE_PATH + HEALTH_PATH,
    API_STAMMDATEN_HEALTH: env => env.API_EFONDS + API_FDB_STAMMDATEN_BASE_PATH + HEALTH_PATH,
    API_LOGINS_HEALTH: env => env.API_EFONDS + API_LOGINS_BASE_PATH + HEALTH_PATH,
    API_SUBSITE_CONTENT_HEALTH: env => env.API_EFONDS + API_SUBSITE_CONTENT_BASE_PATH + HEALTH_PATH,

    API_PRODUKTE_ZWM: env => env.API_EFONDS + API_PRODUKTE_ZWM_PATH,
    API_PRODUKTE_ZWM_TRANCHE: env => env.API_EFONDS + API_TRANCHE_PATH,
    API_PRODUKTE_ZWM_TRANCHE_NEWS: env => env.API_EFONDS + API_TRANCHE_NEWS_PATH,
    API_PRODUKTE_ZWM_TRANCHE_VERMITTLUNGEN: env => env.API_EFONDS + API_TRANCHE_VERMITTLUNGEN_PATH,
    API_PRODUKTE_ZWM_TRANCHE_VERMITTLUNGEN_HISTORIC: env => env.API_EFONDS + API_TRANCHE_VERMITTLUNGEN_HISTORIC_PATH,
    API_PRODUKTE_ZWM_ANBIETER: env => env.API_EFONDS + API_PRODUKTE_ZWM_ANBIETER_PATH,
    API_PRODUKTE_ZWM_CURRENCIES: env => env.API_EFONDS + API_PRODUKTE_ZWM_CURRENCIES_PATH,
    API_PRODUKTE_ZWM_ASSETKLASSEN: env => env.API_EFONDS + API_PRODUKTE_ZWM_ASSETKLASSEN_PATH,
    API_PRODUKTE_ZWM_DECODE_TRANCHE_ID: env => env.API_EFONDS + API_PRODUKTE_ZWM_DECODE_TRANCHE_ID_PATH,
    API_PRODUKTE_ZWM_TRANCHE_ID: env => env.API_EFONDS + API_PRODUKTE_ZWM_TRANCHE_ID_PATH,
    API_PRODUKTE_BEOBACHTUNGEN: env => env.API_EFONDS + API_PRODUKTE_PATH_BEOBACHTUNGEN,
    API_PRODUKTE_BEOBACHTUNGEN_ADD: env => env.API_EFONDS + API_PRODUKTE_PATH_BEOBACHTUNGEN_ADD,
    API_PRODUKTE_BEOBACHTUNGEN_DELETE: env => env.API_EFONDS + API_PRODUKTE_PATH_BEOBACHTUNGEN_DELETE,
    API_TRANCHE_ORDERBUCH: env => env.API_EFONDS + API_TRANCHE_ORDERBUCH_PATH,
    API_TRANCHE_HANDELSINFORMATIONEN: env => env.API_EFONDS + API_TRANCHE_HANDELSINFORMATIONEN_PATH,
    API_KAEUFERMAIL: env => env.API_EFONDS + API_KAEUFERMAIL_PATH,
    API_KAEUFERMAILDATE: env => env.API_EFONDS + API_KAEUFERMAILDATE_PATH,
    API_USER_AUFTRAEGE: env => env.API_EFONDS + API_USER_AUFTRAEGE_PATH,
    API_USER_AUFTRAGNOTE: env => env.API_EFONDS + API_USER_AUFTRAGNOTE_PATH,
    API_AUFTRAGID: env => env.API_EFONDS + API_AUFTRAGID_PATH,
    API_AUFTRAGHEADER: env => env.API_EFONDS + API_AUFTRAGDETAILS_HEADER,
    API_AUFTRAGHISTORIE: env => env.API_EFONDS + API_AUFTRAGDETAILS_HISTORIE,
    API_AUFTRAGDOKUMENTE: env => env.API_EFONDS + API_AUFTRAGDOKUMENTE_PATH,
    API_BIETVERFAHREN_IN_PROGRESS: env => env.API_EFONDS + API_AUFTRAEGE_BIETVERFAHREN_RUNNING_PATH,
    API_AUFTRAG_ANLAGE: env => env.API_EFONDS + API_AUFTRAG_ANLAGE_PATH,
    API_AUFTRAG_AENDERUNG: env => env.API_EFONDS + API_AUFTRAG_AENDERUNG_PATH,
    API_AUFTRAG_LOESCHUNG: env => env.API_EFONDS + API_AUFTRAG_LOESCHUNG_PATH,
    API_USER_VERMITTLUNGEN: env => env.API_EFONDS + API_USER_VERMITTLUNGEN_PATH,
    API_USER_VERMITTLUNGNOTE: env => env.API_EFONDS + API_USER_VERMITTLUNGNOTE_PATH,
    API_VERMITTLUNGID: env => env.API_EFONDS + API_VERMITTLUNGID_PATH,
    API_VERMITTLUNGHEADER: env => env.API_EFONDS + API_VERMITTLUNGDETAILS_HEADER,
    API_VERMITTLUNGHISTORIE: env => env.API_EFONDS + API_VERMITTLUNGDETAILS_HISTORIE,
    API_LOGIN: env => env.API_EFONDS + API_LOGIN_PATH,
    API_PASSWORD_GUIDLINES: env => env.API_EFONDS + API_PASSWORD_GUIDELINES_PATH,
    API_PASSWORD_HINT: env => env.API_EFONDS + API_PASSWORD_HINT_PATH,
    API_PASSWORD_CHECK: env => env.API_EFONDS + API_PASSWORD_GUIDELINE_CHECK_PATH,
    API_PASSWORD_CHANGE: env => env.API_EFONDS + API_PASSWORD_CHANGE_PATH,
    API_PASSWORD_RESETREQUEST: env => env.API_EFONDS + API_PASSWORD_RESETREQUEST_PATH,
    API_REGISTRATION_START: env => env.API_EFONDS + API_REGISTRATION_START_PATH,
    API_REGISTRATION_CONFIRM: env => env.API_EFONDS + API_REGISTRATION_CONFIRM_PATH,
    API_REGISTRATION_NEWSLETTERCONFIRM: env => env.API_EFONDS + API_REGISTRATION_NEWSLETTERCONFIRM_PATH,
    API_REGISTRATION_LOGINCONFIRM: env => env.API_EFONDS + API_REGISTRATION_LOGINCONFIRM_PATH,
    API_VISIBILITY_CHECK: env => env.API_EFONDS + API_VISIBILITY_CHECK_PATH,
    API_DOWNLOADS_CHECK: env => env.API_EFONDS + API_DOWNLOADS_CHECK_PATH,
    API_VERTRAULICHKEITSZUSTIMMUNG: env => env.API_EFONDS + API_VERTRAULICHKEITSZUSTIMMUNG_PATH,
    API_VERTRAULICHKEITSZUSTIMMUNG_DOWNLOADS: env => env.API_EFONDS + API_VERTRAULICHKEITSZUSTIMMUNG_DOWNLOADS_PATH,
    API_USER_PROFILE: env => env.API_EFONDS + API_USER_PROFILE_SERVICE,
    API_KUNDENDATEN: env => env.API_EFONDS + API_KUNDENDATEN_SERVICE,
    API_ADRESSE: env => env.API_EFONDS + API_ADRESSE_SERVICE,
    API_TELEFON: env => env.API_EFONDS + API_TELEFON_SERVICE,
    API_FAX: env => env.API_EFONDS + API_FAX_SERVICE,
    API_LAENDER: env => env.API_EFONDS + API_LAENDER_SERVICE,
    API_INFOSERVICES: env => env.API_EFONDS + API_INFOSERVICES_SERVICE,
    API_SUBSITE_CONTENT: env => env.API_EFONDS + API_SUBSITE_CONTENT_PATH,
    API_LOGINS_UNSUBSCRIBE: env => env.API_EFONDS + API_LOGINS_UNSUBSCRIBE_PATH,
    API_STAMMDATEN_UNSUBSCRIBE: env => env.API_EFONDS + API_STAMMDATEN_UNSUBSCRIBE_PATH,  
    MATOMO_SITEID: 4711,
  },
  prod: {
    API_STAGE: 'prod',
    API_EFONDS: 'https://gateway.efonds.com',
    URL_EXT_SYSTEM: window.location.protocol + '//' + window.location.hostname,
    API_KEY: 'bcf5ee00-c72a-433d-abd5-edd734f4928a',

    API_PRODUKTE_ZWM_HEALTH: env => env.API_EFONDS + API_PRODUKTE_ZWM_BASE_PATH + HEALTH_PATH,
    API_PRODUKTE_HEALTH: env => env.API_EFONDS + API_PRODUKTE_BASE_PATH + HEALTH_PATH,
    API_AUTH_HEALTH: env => env.API_EFONDS + API_AUTH_BASE_PATH + HEALTH_PATH,
    API_AUFTRAEGE_HEALTH: env => env.API_EFONDS + API_AUFTRAEGE_BASE_PATH + HEALTH_PATH,
    API_VERMITTLUNGEN_HEALTH: env => env.API_EFONDS + API_VERMITTLUNGEN_BASE_PATH + HEALTH_PATH,
    API_REGISTRATION_HEALTH: env => env.API_EFONDS + API_REGISTRATION_BASE_PATH + HEALTH_PATH,
    API_STAMMDATEN_HEALTH: env => env.API_EFONDS + API_FDB_STAMMDATEN_BASE_PATH + HEALTH_PATH,
    API_LOGINS_HEALTH: env => env.API_EFONDS + API_LOGINS_BASE_PATH + HEALTH_PATH,
    API_SUBSITE_CONTENT_HEALTH: env => env.API_EFONDS + API_SUBSITE_CONTENT_BASE_PATH + HEALTH_PATH,

    API_PRODUKTE_ZWM: env => env.API_EFONDS + API_PRODUKTE_ZWM_PATH,
    API_PRODUKTE_ZWM_TRANCHE: env => env.API_EFONDS + API_TRANCHE_PATH,
    API_PRODUKTE_ZWM_TRANCHE_NEWS: env => env.API_EFONDS + API_TRANCHE_NEWS_PATH,
    API_PRODUKTE_ZWM_TRANCHE_VERMITTLUNGEN: env => env.API_EFONDS + API_TRANCHE_VERMITTLUNGEN_PATH,
    API_PRODUKTE_ZWM_TRANCHE_VERMITTLUNGEN_HISTORIC: env => env.API_EFONDS + API_TRANCHE_VERMITTLUNGEN_HISTORIC_PATH,
    API_PRODUKTE_ZWM_ANBIETER: env => env.API_EFONDS + API_PRODUKTE_ZWM_ANBIETER_PATH,
    API_PRODUKTE_ZWM_CURRENCIES: env => env.API_EFONDS + API_PRODUKTE_ZWM_CURRENCIES_PATH,
    API_PRODUKTE_ZWM_ASSETKLASSEN: env => env.API_EFONDS + API_PRODUKTE_ZWM_ASSETKLASSEN_PATH,
    API_PRODUKTE_ZWM_DECODE_TRANCHE_ID: env => env.API_EFONDS + API_PRODUKTE_ZWM_DECODE_TRANCHE_ID_PATH,
    API_PRODUKTE_ZWM_TRANCHE_ID: env => env.API_EFONDS + API_PRODUKTE_ZWM_TRANCHE_ID_PATH,
    API_PRODUKTE_BEOBACHTUNGEN: env => env.API_EFONDS + API_PRODUKTE_PATH_BEOBACHTUNGEN,
    API_PRODUKTE_BEOBACHTUNGEN_ADD: env => env.API_EFONDS + API_PRODUKTE_PATH_BEOBACHTUNGEN_ADD,
    API_PRODUKTE_BEOBACHTUNGEN_DELETE: env => env.API_EFONDS + API_PRODUKTE_PATH_BEOBACHTUNGEN_DELETE,
    API_TRANCHE_ORDERBUCH: env => env.API_EFONDS + API_TRANCHE_ORDERBUCH_PATH,
    API_TRANCHE_HANDELSINFORMATIONEN: env => env.API_EFONDS + API_TRANCHE_HANDELSINFORMATIONEN_PATH,
    API_KAEUFERMAIL: env => env.API_EFONDS + API_KAEUFERMAIL_PATH,
    API_KAEUFERMAILDATE: env => env.API_EFONDS + API_KAEUFERMAILDATE_PATH,
    API_USER_AUFTRAEGE: env => env.API_EFONDS + API_USER_AUFTRAEGE_PATH,
    API_USER_AUFTRAGNOTE: env => env.API_EFONDS + API_USER_AUFTRAGNOTE_PATH,
    API_AUFTRAGID: env => env.API_EFONDS + API_AUFTRAGID_PATH,
    API_AUFTRAGHEADER: env => env.API_EFONDS + API_AUFTRAGDETAILS_HEADER,
    API_AUFTRAGHISTORIE: env => env.API_EFONDS + API_AUFTRAGDETAILS_HISTORIE,
    API_AUFTRAGDOKUMENTE: env => env.API_EFONDS + API_AUFTRAGDOKUMENTE_PATH,
    API_BIETVERFAHREN_IN_PROGRESS: env => env.API_EFONDS + API_AUFTRAEGE_BIETVERFAHREN_RUNNING_PATH,
    API_AUFTRAG_ANLAGE: env => env.API_EFONDS + API_AUFTRAG_ANLAGE_PATH,
    API_AUFTRAG_AENDERUNG: env => env.API_EFONDS + API_AUFTRAG_AENDERUNG_PATH,
    API_AUFTRAG_LOESCHUNG: env => env.API_EFONDS + API_AUFTRAG_LOESCHUNG_PATH,
    API_USER_VERMITTLUNGEN: env => env.API_EFONDS + API_USER_VERMITTLUNGEN_PATH,
    API_USER_VERMITTLUNGNOTE: env => env.API_EFONDS + API_USER_VERMITTLUNGNOTE_PATH,
    API_VERMITTLUNGID: env => env.API_EFONDS + API_VERMITTLUNGID_PATH,
    API_VERMITTLUNGHEADER: env => env.API_EFONDS + API_VERMITTLUNGDETAILS_HEADER,
    API_VERMITTLUNGHISTORIE: env => env.API_EFONDS + API_VERMITTLUNGDETAILS_HISTORIE,
    API_LOGIN: env => env.API_EFONDS + API_LOGIN_PATH,
    API_PASSWORD_GUIDLINES: env => env.API_EFONDS + API_PASSWORD_GUIDELINES_PATH,
    API_PASSWORD_HINT: env => env.API_EFONDS + API_PASSWORD_HINT_PATH,     
    API_PASSWORD_CHECK: env => env.API_EFONDS + API_PASSWORD_GUIDELINE_CHECK_PATH,
    API_PASSWORD_CHANGE: env => env.API_EFONDS + API_PASSWORD_CHANGE_PATH,
    API_PASSWORD_RESETREQUEST: env => env.API_EFONDS + API_PASSWORD_RESETREQUEST_PATH,
    API_USER_PROFILE: env => env.API_EFONDS + API_USER_PROFILE_SERVICE,
    API_KUNDENDATEN: env => env.API_EFONDS + API_KUNDENDATEN_SERVICE,
    API_ADRESSE: env => env.API_EFONDS + API_ADRESSE_SERVICE,
    API_TELEFON: env => env.API_EFONDS + API_TELEFON_SERVICE,
    API_FAX: env => env.API_EFONDS + API_FAX_SERVICE,
    API_LAENDER: env => env.API_EFONDS + API_LAENDER_SERVICE,
    API_REGISTRATION_START: env => env.API_EFONDS + API_REGISTRATION_START_PATH,
    API_REGISTRATION_CONFIRM: env => env.API_EFONDS + API_REGISTRATION_CONFIRM_PATH,
    API_REGISTRATION_NEWSLETTERCONFIRM: env => env.API_EFONDS + API_REGISTRATION_NEWSLETTERCONFIRM_PATH,
    API_REGISTRATION_LOGINCONFIRM: env => env.API_EFONDS + API_REGISTRATION_LOGINCONFIRM_PATH,
    API_VISIBILITY_CHECK: env => env.API_EFONDS + API_VISIBILITY_CHECK_PATH,
    API_DOWNLOADS_CHECK: env => env.API_EFONDS + API_DOWNLOADS_CHECK_PATH,
    API_VERTRAULICHKEITSZUSTIMMUNG: env => env.API_EFONDS + API_VERTRAULICHKEITSZUSTIMMUNG_PATH,
    API_VERTRAULICHKEITSZUSTIMMUNG_DOWNLOADS: env => env.API_EFONDS + API_VERTRAULICHKEITSZUSTIMMUNG_DOWNLOADS_PATH,
    API_INFOSERVICES: env => env.API_EFONDS + API_INFOSERVICES_SERVICE,
    API_SUBSITE_CONTENT: env => env.API_EFONDS + API_SUBSITE_CONTENT_PATH,
    API_LOGINS_UNSUBSCRIBE: env => env.API_EFONDS + API_LOGINS_UNSUBSCRIBE_PATH,
    API_STAMMDATEN_UNSUBSCRIBE: env => env.API_EFONDS + API_STAMMDATEN_UNSUBSCRIBE_PATH,
    MATOMO_SITEID: 4711,
  }
}

export const environment = function (env, force = false) {
    if (!force && !process.server) return

    let profile = this.environments[env]

    if (profile) {
        Object.keys(profile).forEach(key => {
            if (typeof profile[key] === 'function') profile[key] = profile[key](profile)
        })

    } else {
        if (env !== this.defaultEnvironment) {
            console.log( 'Defaulting to environment ' + this.defaultEnvironment )
            profile = this.environment(this.defaultEnvironment, force)
        } else {
            console.error(`Default environment "${this.defaultEnvironment}" not found! Check your configuration ./environments.js! Environment variables are not set!`)
        }
    }

    return profile
}

