import axios from "axios";
import store from "@/store";
import router from "../../router";

const data = {
    fondsLoading: false,
    orderLoading: false,
    deleteLoading: false,
    deleteDone: false,
    fondsdata: {},
    orderData: {},
    secondStepData: {},
    thirdStepData: {},
    auftragsTyp: '',

    error401: false,
    error403: false,
    error404: false,
    error406: false,
    error409: false,
    error422: false,
    error500: false,

    deleteError401: false,
    deleteError403: false,
    deleteError404: false,
    deleteError406: false,
}

const actions = {

    fetchFondsData({commit}, id) {
        this.loading = true;
        let token = store.getters['auth/getToken']
        if(token === '' || token === undefined){
            throw new Error('Missing Token!')
        }
        const uri = store.getters.getAllEnvis.API_PRODUKTE_ZWM_TRANCHE + id + '?subsite=' + store.getters["getSubsite"];
        axios.get(
            uri,
            {
                headers: {
                    'Authorization': `Bearer ${token}`,
                    'Accept': 'application/json',
                    'Content-Type': 'application/x-www-form-urlencoded',
                    'XClacksOverhead': 'GNU Terry Pratchett',
                    'X-API-KEY': store.getters.getAllEnvis.API_KEY,
                },
                crossdomain: true,
            }
        )
        .then( response => {
            commit('SET_FONDSDATA', response.data);
            commit('FONDS_LOADING', false);
            this.loading = false;
        })
        .catch(error => {
            console.log(error);
            this.error = error;
            commit('FONDS_LOADING', false);
        })
    },

    fetchOrderData({commit}, auftragId) {

        let token = store.getters['auth/getToken']
        if(token === '' || token === undefined){
            throw new Error('Missing Token!')
        }
        const uri = store.getters.getAllEnvis.API_AUFTRAGHEADER.replace('{auftragId}', auftragId);
        axios.get(
            uri,
            {
                headers: {
                    'Authorization': `Bearer ${token}`,
                    'Accept': 'application/json',
                    'Content-Type': 'application/json'
                },
                crossdomain: true,
            }
        )
        .then( response => {
            commit('SET_AUFTRAGS_DATA', response.data);
        })
        .catch(error => {
            console.log(error);
            this.error = error;
        })
    },

    emptySecondStepData({commit},) {
        commit('EMPTY_SECONDSTEPDATA');
    },

    emptyThirdStepData({commit},) {
        commit('EMPTY_THIRDSTEPDATA');
    },

    resetStatusErrorCodes({commit},) {
        commit('RESET_ERRORCODES');
    },

    saveAuftragsTyp({commit}, payload) {
        commit('SET_AUFTRAGS_TYP', payload);
    },

    saveSecondStepForm({commit}, payload) {
        commit('SET_SECOND_STEP_DATA', {
            limit: payload.limit,
            nominale: payload.nominale,
            validUntil: payload.validUntil,
            partialBrokerage: payload.partialBrokerage,
            division: payload.division,
        });
    },

    saveThirdStepForm({commit}, payload) {
        commit('SET_THIRD_STEP_DATA', payload);
    },
    resetDeleteDone({commit}) {
        commit('DELETE_DONE', false);
    },

    createOrder({commit}, payload) {
        commit('ERROR_401', false);
        commit('ERROR_403', false);
        commit('ERROR_404', false);
        commit('ERROR_406', false);
        commit('ERROR_409', false);
        commit('ERROR_422', false);
        commit('ERROR_500', false);

        commit('ORDER_STATE', true);

        let token = store.getters['auth/getToken']
        if(token === '' || token === undefined){
            throw new Error('Missing Token!')
        }

        const type = payload.auftragArt;
        const trancheId = payload.trancheId;

        const uri = store.getters.getAllEnvis.API_AUFTRAG_ANLAGE;
        axios.post(
            uri,
            payload,
            {
                headers: {
                    'Authorization': `Bearer ${token}`,
                    'Accept': 'application/json',
                    'Content-Type': 'application/json'
                }
            }
        )
        .then( response => {
            commit('ORDER_STATE', false);
            const id = response.data.id;
            const auftragNr = response.data.auftragNr;
            router.push({name: 'AuftragsVerwaltungThanks', params: {client: store.getters['getSubsite']}, query: {type, trancheId, id, auftragNr}});
            return response.data;
        }, error => {
            commit('ORDER_STATE', false);
            if (error.response.status === 401) {
                commit('ERROR_401', true);
            } else if (error.response.status === 403) {
                commit('ERROR_403', true);
            } else if (error.response.status === 404) {
                commit('ERROR_404', true);
            } else if (error.response.status === 409) {
                commit('ERROR_409', true);
            } else if (error.response.status === 422) {
                commit('ERROR_422', true);
            } else if (error.response.status === 500) {
                commit('ERROR_500', true);
            }
            console.log(error);
            this.error = error;
            return error;
        }).catch(error => {
            commit('ORDER_STATE', false);
            console.log(error);
            this.error = error;
        })
    },
    editOrder({commit}, payload) {
        commit('ERROR_401', false);
        commit('ERROR_403', false);
        commit('ERROR_404', false);
        commit('ERROR_406', false);
        commit('ERROR_409', false);
        commit('ERROR_422', false);
        commit('ERROR_500', false);

        commit('ORDER_STATE', true);

        let token = store.getters['auth/getToken']
        if(token === '' || token === undefined){
            throw new Error('Missing Token!')
        }

        const type = payload.auftragArt;
        const trancheId = payload.trancheId;
        const auftragNr = payload.auftragNr;
        const id = payload.id;
        const edit = "1";

        const uri = store.getters.getAllEnvis.API_AUFTRAG_AENDERUNG;

        axios.post(
            uri,
            payload,
            {
                headers: {
                    'Authorization': `Bearer ${token}`,
                    'Accept': 'application/json',
                    'Content-Type': 'application/json'
                }
            }
        )
            .then( response => {
                commit('ORDER_STATE', false);
                router.push({name: 'AuftragsVerwaltungThanks', params: {client: store.getters['getSubsite']}, query: {type, trancheId, auftragNr, id, edit}});
                return response.data;
            }, error => {
                commit('ORDER_STATE', false);
                if (error.response.status === 401) {
                    commit('ERROR_401', true);
                } else if (error.response.status === 403) {
                    commit('ERROR_403', true);
                } else if (error.response.status === 404) {
                    commit('ERROR_404', true);
                } else if (error.response.status === 406) {
                    commit('ERROR_406', true);
                } else if (error.response.status === 409) {
                    commit('ERROR_409', true);
                } else if (error.response.status === 422) {
                    commit('ERROR_422', true);
                } else if (error.response.status === 500) {
                    commit('ERROR_500', true);
                }
                console.log(error);
                this.error = error;
                return error;
            }).catch(error => {
            commit('ORDER_STATE', false);
            console.log(error);
            this.error = error;
        })
    },
    // eslint-disable-next-line no-unused-vars
    deleteOrder({commit}, id) {
        commit('DELETE_ERROR_401', false);
        commit('DELETE_ERROR_403', false);
        commit('DELETE_ERROR_404', false);
        commit('DELETE_ERROR_451', false);

        commit('DELETE_STATE', true);

        let token = store.getters['auth/getToken']
        if(token === '' || token === undefined){
            throw new Error('Missing Token!')
        }

        const uri = store.getters.getAllEnvis.API_AUFTRAG_LOESCHUNG.replace('{auftragId}', id);
        axios.delete(
            uri,
            {
                headers: {
                    'Authorization': `Bearer ${token}`,
                    'Accept': 'application/json',
                    'Content-Type': 'application/json'
                },
                crossdomain: true,
            }
        )
        .then( response => {
            commit('DELETE_ERROR_401', false);
            commit('DELETE_ERROR_403', false);
            commit('DELETE_ERROR_404', false);
            commit('DELETE_ERROR_451', false);

            commit('DELETE_STATE', false);
            location.reload();
            commit('DELETE_DONE', true);
            return response.data;
        }, error => {
            commit('DELETE_STATE', false);
            if (error.response.status === 401) {
                commit('DELETE_ERROR_401', true);
            } else if (error.response.status === 403) {
                commit('DELETE_ERROR_403', true);
            } else if (error.response.status === 404) {
                commit('DELETE_ERROR_404', true);
            } else if (error.response.status === 451) {
                commit('DELETE_ERROR_406', true);
            }
            console.log(error);
            this.error = error;
            return error;
        })
        .catch(error => {
            commit('DELETE_STATE', false);
            console.log(error);
            this.error = error;
        })
    },
};

const mutations = {
    SET_FONDSDATA: (state, data) =>{
        state.fondsdata = data
    },
    SET_AUFTRAGS_DATA:  (state, data) => {
        state.orderData = data
    },
    FONDS_LOADING: (state, fondsLoading) => {
        state.fondsLoading = fondsLoading
    },
    ORDER_STATE: (state, orderLoading) => {
        state.orderLoading = orderLoading
    },
    DELETE_STATE: (state, deleteLoading) => {
        state.deleteLoading = deleteLoading
    },
    DELETE_DONE: (state, deleteDone) => {
        state.deleteDone = deleteDone
    },
    SET_SECOND_STEP_DATA: (state, data) =>{
        state.secondStepData = data
    },
    SET_THIRD_STEP_DATA: (state, data) =>{
        state.thirdStepData = data
    },
    SET_AUFTRAGS_TYP: (state, data) =>{
        state.auftragsTyp = data
    },
    EMPTY_SECONDSTEPDATA(state) {
        state.secondStepData = [];
    },
    EMPTY_THIRDSTEPDATA(state) {
        state.thirdStepData = [];
    },
    RESET_ERRORCODES(state) {
        state.error401 = false;
        state.error403 = false;
        state.error404 = false;
        state.error406 = false;
        state.error409 = false;
        state.error422 = false;
        state.error500 = false;
    },

    ERROR_401: (state, error401) => {
        state.error401 = error401
    },
    ERROR_403: (state, error403) => {
        state.error403 = error403
    },
    ERROR_404: (state, error404) => {
        state.error404 = error404
    },
    ERROR_406: (state, error406) => {
        state.error406 = error406
    },
    ERROR_409: (state, error409) => {
        state.error409 = error409
    },
    ERROR_422: (state, error422) => {
        state.error422 = error422
    },
    ERROR_500: (state, error500) => {
        state.error500 = error500
    },
    DELETE_ERROR_401: (state, deleteError401) => {
        state.deleteError401 = deleteError401
    },
    DELETE_ERROR_403: (state, deleteError403) => {
        state.deleteError403 = deleteError403
    },
    DELETE_ERROR_404: (state, deleteError404) => {
        state.deleteError404 = deleteError404
    },
    DELETE_ERROR_406: (state, deleteError406) => {
        state.deleteError406 = deleteError406
    },
};

const getters = {
    getAllEnvis: state => state.environmental,
    fondsdata: state => state.fondsdata,
    orderData: state => state.orderData,
    fondsLoading: state => state.fondsLoading,
    orderLoading: state => state.orderLoading,
    deleteLoading: state => state.deleteLoading,
    deleteDone: state => state.deleteDone,
    secondStepData: state => state.secondStepData,
    thirdStepData: state => state.thirdStepData,
    auftragsTyp: state => state.auftragsTyp,
    error401: state => state.error401,
    error403: state => state.error403,
    error404: state => state.error404,
    error406: state => state.error406,
    error409: state => state.error409,
    error422: state => state.error422,
    error500: state => state.error500,
    deleteError401: state => state.deleteError401,
    deleteError403: state => state.deleteError403,
    deleteError404: state => state.deleteError404,
    deleteError451: state => state.deleteError406,
}

export default {
    namespaced: true,
    state: data,
    actions,
    getters,
    mutations,
};


